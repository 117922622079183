import { projets } from "./components/DataProjects";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Helmet } from "react-helmet";

export default function Projects() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      });

    useEffect(() => {
        document.querySelectorAll('.up-link').forEach(link => {
          link.addEventListener('click', function(){
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          })
        });
      });

    useEffect(() => {
        let tlHome = gsap.timeline({ duration: .2, ease: 'Power3.inOut', stagger: {amount: 0.3} });
    
        tlHome.fromTo(['header'], { opacity: 0, y: 50 }, { opacity: 1, y: 0 });
        
        }, []);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        // let tlProject = gsap.timeline( { scrollTrigger: { trigger: '.project-item', start: '0', markers: true, pin: false } } );

        // document.querySelectorAll(".project-category-wrapper-single::before").forEach((item) => {
        //     gsap.to(item, {
        //       scrollTrigger: {
        //         trigger: item,
        //         start: "top center",
        //         end: "20% center",
        //         scrub: false,
        //         markers: true
        //       },
        //       duration: 0.6,
        //       opacity: 1,
        //       ease: Power3.easeInOut
        //     })});

        var elementAppear = document.querySelectorAll('.project-category-wrapper-single');
        let i;

        function revealElement(){
                for( i = 0; i < elementAppear.length; i++ ){

                var windowHeight = window.innerHeight;
                var topPoint = elementAppear[i].getBoundingClientRect().top;
                var revealpoint = 400;

                if(topPoint < windowHeight - revealpoint){
                    elementAppear[i].classList.add('active');
                } else {
                    elementAppear[i].classList.remove('active');
                }
            }
        }
    
        window.addEventListener('scroll', revealElement);
        // tlProject.fromTo('.project-image', { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.25 });
}, []);

    useEffect(() => {
            // let tlProject = gsap.timeline( { scrollTrigger: { trigger: '.project-item', start: '0', markers: true, pin: false } } );

            document.querySelectorAll(".project-image-wrapper").forEach((item) => {
                gsap.to(item, {
                  scrollTrigger: {
                    trigger: item,
                    start: "top center",
                    end: "20% center",
                    scrub: false,
                    markers: false,
                    toggleActions: "play none none reverse",
                  },
                  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
                  duration: .3,
                  opacity: 1,
                  ease: 'Power3.easeInOut',
                })});
        
            // tlProject.fromTo('.project-image', { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.25 });
    }, []);

    return (
        <>
        <Helmet>
            <title>FS Agencebois – Projets</title>
            <meta name="description" content="Répertoire des différents projets réalisés par l'entreprise FS Agencebois, allant des cuisines, aux armoires, escaliers et divers agencements, le tout sur-mesure." />
        </Helmet>
        {/* <Head>
            <title>FS Agencebois - Projets</title>
            <meta name="description" content="Répertoire des différents projets réalisés par l'entreprise FS Agencebois, allant des cuisines, aux armoires, escaliers et divers agencements, le tout sur-mesure." />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head> */}
        <main className="main">
            <header className="pt-24">
                <div className="layout with-mb-special xl:max-w-desktop-layout mx-auto pl-4 pt-20 xl:pl-0">
                    <h1 className="main-title-project uppercase">Découvrez nos projets</h1>
                    <p className="text-2xl mb-12">
                    Choisissez une catégorie
                    </p>
                    <div className="arrow-down text-5xl md:text-8xl rotate-90 inline-block">
                        ➔
                    </div>
                </div>
            </header>
            <div className="layout mb-20 md:mb-56">
                <div className="project-category-wrapper flex flex-col gap-y-12 md:gap-y-24">
                    <div className="project-category-wrapper-single relative">
                        {projets.slice(0, 1).map((projets, idx) => {
                            if (projets.category === 'cuisine'){    
                                return (
                                    <Link key={idx} className="up-link" to="/Cuisines">
                                        <div className="nav-link project-category">
                                            <h3 className="project-category-title"><span className="mr-4 hidden md:inline opacity-0">➔</span>{projets.category}</h3>
                                            <div className="project-image-wrapper md:max-w-1/2">
                                                <img className="w-full" src={`img/${projets.img}`} alt="" />
                                            </div>
                                        </div>
                                    </Link>
                                );
                            } else {
                                return (null)
                            }
                        })}
                    </div>
                    <div className="project-category-wrapper-single relative">
                        {projets.slice(0, 2).map((projets, idx) => {
                            if (projets.category === 'divers'){
                                return (
                                    <Link key={idx} className="nav-link" to="/Divers">
                                        <div className="up-link project-category">
                                            <h3 className="project-category-title"><span className="mr-4 hidden md:inline opacity-0">➔</span>{projets.category}</h3>
                                            <div className="project-image-wrapper md:max-w-1/2">
                                                <img className="w-full" src={`img/${projets.img}`} alt="" />
                                            </div>
                                        </div>
                                    </Link>
                                );
                            } else {
                                return (null)
                            }
                        })}
                    </div>
                    <div className="project-category-wrapper-single relative">
                        {projets.slice(0, 9).map((projets, idx) => {
                            if (projets.category === 'Salle de bain'){
                                return (
                                    <Link key={idx} className="nav-link" to="/Salle-de-Bains">
                                        <div className="up-link project-category">
                                            <h3 className="project-category-title"><span className="mr-4 hidden md:inline opacity-0">➔</span>{projets.category}</h3>
                                            <div className="project-image-wrapper md:max-w-1/2">
                                                <img className="w-full" src={`img/${projets.img}`} alt="" />
                                            </div>
                                        </div>
                                    </Link>
                                );
                            } else {
                                return (null)
                            }
                        })}
                    </div>
                    <div className="project-category-wrapper-single relative">
                        {projets.slice(0, 13).map((projets, idx) => {
                            if (projets.category === 'armoire'){
                                return (
                                    <Link key={idx} className="nav-link" to="/Armoires">
                                        <div className="up-link project-category">
                                            <h3 className="project-category-title"><span className="mr-4 hidden md:inline opacity-0">➔</span>{projets.category}</h3>
                                            <div className="project-image-wrapper md:max-w-1/2">
                                                <img className="w-full" src={`img/${projets.img}`} alt="" />
                                            </div>
                                        </div>
                                    </Link>
                                );
                            } else {
                                return (null)
                            }
                        })}
                    </div>
                </div>
            </div>
        </main>
      </>
    )
}