import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>FS Agencebois – Page d'erreur</title>
        </Helmet>
      <div className="min-h-screen text-center flex-col flex justify-center items-center">404 error <br /> Vous vous être perdus !
      <Link to="/" className="nav-link common-link inline-block relative -z-10 font-bold">Retourner à la page d'accueil</Link>
      </div>
    </>
  )
}

export default PageNotFound