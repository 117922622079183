import React from 'react';
import { Link } from 'react-router-dom';

const BackLink = () => {
  return (
    <>
        <Link to="/Projets" className="return-button nav-link">
            Autres Projets
        </Link>
        <Link to="/Projets" className="return-button-arrow">
            <span className=" inline-block rotate-180">➔</span>
        </Link>
    </>
  )
}

export default BackLink