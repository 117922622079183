import { useEffect } from "react";
import gsap from "gsap";
import { Helmet } from "react-helmet";

export default function Contact() {

    useEffect(() => {
        let tlHome = gsap.timeline({ duration: .1, ease: 'Power3.in' });

        tlHome.fromTo(['.contact-title', '.contact-info'], { opacity: 0, y: 50 }, { opacity: 1, y: 0 });
    }, []);

    useEffect(() => {
        let tlHome = gsap.timeline({ duration: .2, ease: 'Power3.in' });

        tlHome.fromTo(['.contact-map'], { clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)' }, { clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)', delay: .05 });
    }, []);

    // let appearContact = gsap.timeline({ defaults: { ease: Power3.easeInOut } });

    // useEffect(() => {
    //     appearContact.fromTo('.contact-content', { clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0 100%)' }, { clipPath: 'polygon(0 100%, 100% 100%, 100% 0, 0 0)', duration: .9 });
    // });

    return (
        <>
        <Helmet>
            <title>FS Agencebois – Contact</title>
            <meta name="description" content="Contactez FS Agencebois afin de parler de votre futur projet de menuiserie." />
        </Helmet>
            {/* <Head>
            <title>Contact</title>
            <meta name="description" content="Contactez FS Agencebois afin de parler de votre projet de menuiserie." />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head> */}
            {/* <Nav></Nav> */}
            <main>
                <header className="contact-header">
                    <div className="layout xl:max-w-desktop-layout mx-auto pl-4 pt-20 xl:pl-0">
                        <h1 className="contact-title main-title">Contactez-nous</h1>
                    </div>
                </header>
                <div className="layout mb-20 md:mb-32">
                    <div className="contact-content flex flex-col md:flex-row justify-between gap-8 md:h-halfScreen">
                        <div className="contact-info pl-4 md:pl-8 pt-10">
                            <div className="mb-14">
                                <h3 className="contact-info-title text-xl mb-4 font-bold">
                                    Contact
                                </h3>
                                <a className="block mb-4" href="mailto:info@fsagencebois.ch">
                                    <div className="contact-button text-xl md:text-2xl common-link relative inline-block">
                                        info@fsagencebois.ch
                                    </div>
                                </a>
                                <a className="block" href="tel:0219489900">
                                    <div className="contact-button text-xl md:text-2xl common-link relative inline-block">
                                        021 948 99 00
                                    </div>
                                </a>
                            </div>
                            <div className="">
                                <h3 className="contact-info-title text-xl mb-4 font-bold">
                                    Suivez-nous sur les réseaux
                                </h3>
                                <div className="flex gap-4 md:gap-8">
                                    <a className="" href="https://www.instagram.com/fsagencebois/" target="_blank" rel="noreferrer"><li className="common-link relative inline-block"><i className="fa-brands fa-instagram text-2xl"></i></li></a>
                                    <a className="" href="https://www.facebook.com/sauteur.frederic.5/" target="_blank" rel="noreferrer"><li className="common-link relative inline-block"><i className="fa-brands fa-facebook-f text-2xl"></i></li></a>
                                </div>
                            </div>
                        </div>
                        <div className="contact-map">
                            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d925.5427921664786!2d6.878452513030906!3d46.52890546628051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e83eed0fa0453%3A0xd1d39e5d0aa6bf0!2sF.S.%20Agencebois%20S%C3%A0rl!5e0!3m2!1sfr!2sch!4v1680182441992!5m2!1sfr!2sch" allowFullScreen="" className="w-full h-full" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}