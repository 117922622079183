import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Values from './components/Values';
import Devis from './components/Devis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Helmet } from "react-helmet";


const Home = () => {

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let tlHome = gsap.timeline({ duration: .1, ease: 'Power3.InOut' });

    tlHome.to(['.header'], { clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)' });

    // tlHome.to(['.home-title'], { opacity: 1, y: 0 });
    
  }, []);

  useEffect(() => {
    let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.description-container', start: '-700', markers: false, pin: false } });

    tlValues.fromTo(['.home-image','.home-description'], { opacity: 0, y: '30px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.15 });
}, []);

  useEffect(() => {
    let tlHome = gsap.timeline({ duration: .3, ease: 'Power3.inOut', stagger: {amount: 0.3} });

    tlHome.to(['.home-title'], { opacity: 1, y: 0 });
    
  }, []);

  // useEffect(() => {
  //     let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.prestation-container', start: '-400', markers: false, pin: false, toggleActions: "play none none reverse", } });

  //     tlValues.fromTo(['.prestation-title', '.prestation-item', '.devis'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.15 });
  // }, []);

  return (
    <>
      <Helmet>
        <title>FS Agencebois</title>
      </Helmet>
      <main className="main">
      <header className="header bg-hero-home mb-20 md:mb-32">
        <div className="layout xl:max-w-desktop-layout mx-auto pl-4 pt-20 xl:pl-0">
          <h1 className="home-title translate-y-8 opacity-0 text-3xl md:text-5xl xl:text-6xl font-bold text-white uppercase mb-2 md:mb-4 selection:bg-dark-color"><span className="border-yellow border-b-4">Menuiserie</span> & <br /> <span className="border-yellow border-b-4">Agencement</span>
          </h1>
        </div>
      </header>
      <div className="description-container layout with-mb">
        <section className="flex flex-col-reverse md:flex-row gap-8 md:gap-12 md:items-center">
          {/* <div className="image-wrapper overflow-hidden flex justify-center items-center max-w-1/2"> */}
            <img className="home-image md:max-w-1/2" src="./img/cuisines/cuisine5.webp" alt="Cuisine." />
          {/* </div> */}
          <div className="home-description">
            <h3 className="mb-4 opacity-75">
              01 - A Propos
            </h3>
            <h2 className="font-bold text-3xl mb-4">FS AGENCEBOIS</h2>
            <p className="common-text mb-6">
              Nous sommes une entreprise formatrice qui vous propose un service artisanal de qualité suisse. Nous fabriquons tous types d’agencements intérieurs sur-mesure, selon les matériaux et les couleurs de votre choix.

              <br />
              <br />

              Nous restons à disposition pour toutes informations complémentaires. Contactez-nous afin de parler de votre projet.
              <br />
              <br />
            <Link to="/Projets" className="nav-link common-link inline-block relative -z-10 font-bold">Voir les Projets</Link>
            </p>
            <div>
            {/* <Link to="/Contact" className="nav-link common-link relative font-bold uppercase text-xl">
              Contactez-nous ➔
            </Link> */}
            </div>
          </div>
        </section>
    </div>
    <Values></Values>
    {/* <div className="layout mb-20 md:mb-32">
        <h2 className="font-bold text-3xl mb-4">Projets Récents</h2>
        <div className="flex flex-col md:flex-row justify-center flex-wrap lg:flex-nowrap gap-4 mb-6 md:mb-12">
          {projets.slice(0, 3).map((projets, index) => {
            return (
              <div className="gallery-preview cursor-pointer">
                <img className="" src={`img/${projets.img}`} alt="Image." />
              </div>
            )
          })}
        </div>
        <div className="button-wrapper w-48 mx-auto flex justify-center">
          <Link className="common-link inline-block relative font-bold text-2xl" href="/Projects">
            Plus de projets
          </Link>
        </div>
    </div> */}
    <Devis></Devis>
  </main>
</>
  )
}

export default Home